<template>
  <svg fill="none" height="32" viewBox="0 0 32 32" width="32" xmlns="http://www.w3.org/2000/svg">
    <rect fill="#FF8000" height="32" rx="16" width="32"></rect>
    <path d="M15.9992 2.16016C8.35918 2.16016 2.15918 8.36016 2.15918 16.0002C2.15918 23.6402 8.35918 29.8402 15.9992 29.8402C23.6392 29.8402 29.8392 23.6402 29.8392 16.0002C29.7992 8.36016 23.6392 2.20016 15.9992 2.16016ZM15.9992 28.6402C9.03918 28.6402 3.35918 22.9602 3.35918 16.0002C3.35918 9.04016 9.03918 3.36016 15.9992 3.36016C22.9592 3.36016 28.6392 9.04016 28.6392 16.0002C28.5992 22.9602 22.9592 28.6002 15.9992 28.6402Z" fill="white"></path>
    <path d="M8.55908 6.6001L12.8791 16.0001L8.55908 25.4001L25.2391 16.0001L8.55908 6.6001ZM11.1991 9.4801L21.7191 15.4001H13.9191L11.1991 9.4801ZM13.9191 16.6001H21.7191L11.1991 22.5201L13.9191 16.6001Z" fill="white"></path>
  </svg>
</template>

<script>
export default {
  name: 'NoteSendIcon'
}
</script>
